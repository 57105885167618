<template>
    <div class="metaverse-block">
        <div class="game-world-block">
            <div class="container">
                <h2 class="title">
                    {{ $i18n.get(`Game Worlds`) }}
                </h2>

                <div class="sub-title">
                    {{ $i18n.get(`Games from the leaders of the GameFi industry`) }}
                </div>

                <div class="description">
                    {{ $i18n.get(`A game world is one or more games from a game provider with their own gameplay, mechanics and storyline. You can simultaneously play in several worlds, developing your internal gaming assets.`) }}
                </div>

                <div class="description">
                    {{ $i18n.get(`The interaction between the worlds takes place through the NFT. You can receive additional benefits from your assets in other worlds. Or completely transfer your assets to another external world.`) }}
                </div>
            </div>
        </div>

        <div class="ecosystem-block">
            <div class="container">
                <h1 class="title">
                    {{ $i18n.get(`A GameFi Ecosystem`) }}
                </h1>

                <div class="sub-title">
                    {{ $i18n.get(`For gamers, brands and developers`) }}
                </div>

                <div class="blocks">
                    <div class="item">
                        <svg width="68" height="47" viewBox="0 0 68 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.7 3.9569L27.8 7.98589H40.2L43.3 3.9569M24.7 33.1448L15.4992 42.0918C14.4752 43.0874 13.1706 43.7653 11.7504 44.04C10.3302 44.3146 8.85809 44.1736 7.52027 43.6348C6.18246 43.096 5.03897 42.1835 4.23438 41.0128C3.42979 39.8421 3.00023 38.4657 3 37.0576V33.1448L7.2067 12.6946C7.76856 9.96111 9.28699 7.50138 11.5037 5.73388C13.7203 3.96637 16.4983 3.00029 19.3649 3H48.6351C51.5017 3.00029 54.2797 3.96637 56.4963 5.73388C58.713 7.50138 60.2314 9.96111 60.7933 12.6946L65 33.1448V37.0546C64.9998 38.4627 64.5702 39.8391 63.7656 41.0098C62.961 42.1805 61.8175 43.093 60.4797 43.6318C59.1419 44.1706 57.6698 44.3116 56.2496 44.037C54.8294 43.7623 53.5248 43.0844 52.5008 42.0888L43.3 33.1448H24.7Z" stroke="url(#paint0_linear_1_1472)" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            <defs>
                                <linearGradient id="paint0_linear_1_1472" x1="-22.5191" y1="-28.0544" x2="69.9565" y2="-38.4463" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.00520833" stop-color="#FCB42F"/>
                                    <stop offset="1" stop-color="#CB34E3"/>
                                </linearGradient>
                            </defs>
                        </svg>

                        <div class="title">
                            {{ $i18n.get(`Gamers`) }}
                        </div>

                        <div class="description">
                            {{ $i18n.get(`Gamers are invited to a variety of game worlds combined all in one place.`) }}
                        </div>

                        <w-button v-if="$store.state.isGuest" @click="signUp()" type="orange" size="middle">
                            {{ $i18n.get(`Sign up`) }}
                        </w-button>

                        <w-button type="blue" size="middle" v-else @click="pushToTelegram()">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_51_1106)">
                                    <path d="M0.353381 9.64303L4.96178 11.363L6.74551 17.0995C6.85965 17.4669 7.30891 17.6027 7.60711 17.3589L10.1759 15.2648C10.4452 15.0454 10.8287 15.0344 11.1101 15.2387L15.7433 18.6025C16.0623 18.8344 16.5142 18.6596 16.5942 18.2741L19.9883 1.94803C20.0756 1.52696 19.6619 1.17569 19.261 1.33076L0.347981 8.62683C-0.118752 8.80683 -0.114686 9.46763 0.353381 9.64303ZM6.45805 10.4474L15.4646 4.90029C15.6264 4.80089 15.793 5.01976 15.654 5.14869L8.22098 12.058C7.95971 12.3012 7.79118 12.6267 7.74345 12.98L7.49025 14.8564C7.45671 15.1069 7.10478 15.1318 7.03565 14.8893L6.06185 11.4676C5.95031 11.0774 6.11285 10.6605 6.45805 10.4474Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_51_1106">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>

                            Telegram
                        </w-button>

                        <div class="reference">
                            {{ $i18n.get(`Be the first to know about the launch`) }}
                        </div>
                    </div>

                    <div class="item">
                        <svg width="68" height="65" viewBox="0 0 68 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.8699 3.9934C32.6414 2.66886 34.5549 2.66887 35.3263 3.9934L42.8281 16.8732C43.9585 18.814 45.8527 20.1902 48.0479 20.6655L62.6154 23.8201C64.1135 24.1445 64.7048 25.9644 63.6835 27.1074L53.7523 38.2221C52.2558 39.8969 51.5323 42.1237 51.7586 44.3583L53.26 59.1876C53.4144 60.7127 51.8664 61.8374 50.4637 61.2193L36.8241 55.2088C34.7688 54.3031 32.4274 54.3031 30.3721 55.2088L16.7325 61.2193C15.3298 61.8374 13.7818 60.7127 13.9362 59.1876L15.4377 44.3583C15.6639 42.1237 14.9404 39.8969 13.4439 38.2221L3.51268 27.1074C2.49138 25.9644 3.08268 24.1445 4.58079 23.8201L19.1483 20.6655C21.3435 20.1902 23.2377 18.814 24.3681 16.8732L31.8699 3.9934Z" stroke="url(#paint0_linear_1_1320)" stroke-width="6"/>
                            <defs>
                                <linearGradient id="paint0_linear_1_1320" x1="-27.6578" y1="-48.5661" x2="73.2219" y2="-56.4223" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.00520833" stop-color="#FCB42F"/>
                                    <stop offset="1" stop-color="#CB34E3"/>
                                </linearGradient>
                            </defs>
                        </svg>

                        <div class="title">
                            {{ $i18n.get(`Brands`) }}
                        </div>

                        <div class="description">
                            {{ $i18n.get(`Brands can quickly mint and sell their own NFTs, which can be used by players in game worlds.`) }}
                        </div>

                        <w-button type="blue" size="middle" href="https://business.truefuture.io/solutions/for-brands">
                            {{ $i18n.get(`For brands`) }}
                        </w-button>

                        <div class="reference">
                            {{ $i18n.get(`Join the NFT and GameFi trend with innovative technology`) }}
                        </div>
                    </div>

                    <div class="item">
                        <svg width="66" height="60" viewBox="0 0 66 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.37258 0 0 5.37258 0 12V48C0 54.6274 5.37258 60 12 60H54C60.6274 60 66 54.6274 66 48V12C66 5.37258 60.6274 0 54 0H12ZM13 5C8.58172 5 5 8.58172 5 13V47C5 51.4183 8.58172 55 13 55H53C57.4183 55 61 51.4183 61 47V13C61 8.58172 57.4183 5 53 5H13Z" fill="url(#paint0_linear_1_511)"/>
                            <path d="M31.25 44.5249C31.25 44.8504 31.5156 45.1167 31.8359 45.1167H46.2891C46.6094 45.1167 46.875 44.8504 46.875 44.5249V40.9747C46.875 40.6492 46.6094 40.383 46.2891 40.383H31.8359C31.5156 40.383 31.25 40.6492 31.25 40.9747V44.5249ZM16.0234 44.9761L31.0234 33.0679C31.3203 32.8312 31.3203 32.3948 31.0234 32.1581L16.0234 20.2573C15.9329 20.1844 15.8221 20.1376 15.7044 20.1226C15.5866 20.1075 15.4667 20.1247 15.3589 20.1722C15.2512 20.2197 15.1601 20.2955 15.0964 20.3906C15.0328 20.4856 14.9994 20.596 15 20.7085V25.346C15 25.5236 15.0781 25.6863 15.2266 25.7972L23.8047 32.6167L15.2266 39.4362C15.1567 39.4916 15.1003 39.5605 15.0612 39.6384C15.0221 39.7162 15.0013 39.8012 15 39.8874V44.5249C15 45.0279 15.6172 45.3016 16.0234 44.9761Z" fill="url(#paint1_linear_1_511)"/>
                            <defs>
                                <linearGradient id="paint0_linear_1_511" x1="-27.1655" y1="-45.2504" x2="71.852" y2="-53.3793" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.00520833" stop-color="#FCB42F"/>
                                    <stop offset="1" stop-color="#CB34E3"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_1_511" x1="-27.1655" y1="-45.2504" x2="71.852" y2="-53.3793" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.00520833" stop-color="#FCB42F"/>
                                    <stop offset="1" stop-color="#CB34E3"/>
                                </linearGradient>
                            </defs>
                        </svg>

                        <div class="title">
                            {{ $i18n.get(`Developers`) }}
                        </div>

                        <div class="description">
                            {{ $i18n.get(`Developers are set free from building their own infrastructure to focus solely on game content.`) }}
                        </div>

                        <w-button type="blue" size="middle" href="https://business.truefuture.io/solutions/for-developers">
                            {{ $i18n.get(`For developers`) }}
                        </w-button>

                        <div class="reference">
                            {{ $i18n.get(`We invite the best projects to join TRUE Metaverse`) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'metaverse',
    methods: {
        pushToTelegram() {
            window.open(this.$store.state.links.telegramNews)
        },
        signUp() {
            window.location.hash = '#/signup/'
        },
        openForBrands() {
            window.open('https://brands.truefuture.io')
        }
    },
    mounted() {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 10)
    }
}
</script>

<style lang="less">
.metaverse-block {
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .game-world-block {
        position: relative;
        background-color: var(--dark-blue);
        background-image: url('./images/metaverse-bg-game-world.webp');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 2560px 760px;
        min-height: 760px;
        padding: 105px 0;

        > .container {
            width: var(--main-width-full);
            margin: 0 auto;

            > .title {
                position: relative;
                display: inline-block;
                font-weight: 800;
                font-size: 46px;
                line-height: 144%;

                &::after {
                    content: 'soon';
                    position: absolute;
                    top: 18px;
                    right: -78px;
                    display: block;
                    padding: 6px 15px;
                    background: linear-gradient(85.73deg, #5F8CFF -25.39%, #CB34E3 114.43%);
                    border-radius: 15px 15px 15px 0;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            > .sub-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
            }

            > .description {
                max-width: 500px;
                margin-top: 20px;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
            }
        }
    }

    > .ecosystem-block {
        background: var(--dark-blue) linear-gradient(180deg, #001A54 14.66%, #0D255D 100%);
        min-height: 460px;
        padding: 0 15px 0;

        > .container {
            position: relative;
            width: var(--main-width-full);
            margin: -175px auto 0;

            > .title {
                font-weight: 700;
                font-size: 36px;
                line-height: 144%;
            }

            > .sub-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 180%;
            }

            > .blocks {
                display: flex;
                column-gap: 35px;
                margin-top: 40px;

                > .item {
                    width: 390px;
                    background: linear-gradient(180deg, rgba(74, 98, 190, 0.3) 0%, rgba(114, 133, 202, 0.3) 100%);
                    backdrop-filter: blur(10px);
                    padding: 45px 40px 40px;
                    border-radius: 30px;
                    border: 1px solid var(--white-10);
                    display: flex;
                    flex-direction: column;
                    transition: all 0.5s;

                    > svg {
                        height: 60px;
                        margin-bottom: 10px;
                    }

                    > .title {
                        margin-bottom: 10px;
                        font-weight: 800;
                        font-size: 20px;
                        line-height: 26px;
                    }

                    > .description {
                        margin-bottom: 20px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                    }

                    > .btn {
                        margin-bottom: 15px;
                        align-self: flex-start;
                    }

                    > .reference {
                        color: var(--white-60);
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                        transition: all 0.5s;
                    }

                    &:hover {
                        color: var(--dark-blue);
                        background: var(--white);
                        transform: translateY(-10px);

                        > .reference {
                            color: var(--dark-blue-60);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1279px) {
    .metaverse-block {
        .game-world-block {
            min-height: 780px;
            padding: 55px 0 0;

            > .container {
                > .description {
                    max-width: 380px;
                }
            }
        }

        > .ecosystem-block {
            min-height: 350px;

            > .container {
                margin: -235px auto 0;

                > .blocks {
                    column-gap: 18px;
                    margin-top: 30px;

                    > .item {
                        width: 270px;
                        padding: 30px;

                        > svg {
                            height: 65px;
                        }

                        > .title {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        > .description {
                            font-size: 14px;
                            line-height: 24px;
                            min-height: 100px;
                        }

                        > .btn {
                            align-self: flex-start;
                        }

                        > .reference {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .metaverse-block {
        .game-world-block {
            min-height: 770px;
            padding: 50px 0 0;

            > .container {
                > .title {
                    font-weight: 800;
                    font-size: 32px;
                    line-height: 144%;

                    &::after {
                        top: 7px;
                        padding: 4px 15px;
                    }
                }

                > .sub-title {
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 216px;
                }

                > .description {
                    max-width: 276px;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        > .ecosystem-block {
            min-height: 350px;

            > .container {
                > .title {
                    font-size: 32px;
                    line-height: 144%;
                }

                > .sub-title {
                    font-weight: 600;
                    font-size: 16px;
                }

                > .blocks {
                    column-gap: 19px;
                    margin-top: 30px;
                    align-items: stretch;

                    > .item {
                        width: 210px;
                        padding: 20px;

                        > svg {
                            height: 50px;
                        }

                        > .title {
                            font-size: 16px;
                            line-height: 26px;
                        }

                        > .description {
                            font-size: 13px;
                            line-height: 22px;
                            min-height: 90px;
                        }

                        > .btn {
                            align-self: stretch;
                            padding: 14px 30px;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                        }

                        > .reference {
                            font-size: 11px;
                            line-height: 16px;
                            text-align: center;
                            padding: 0px 5px;
                        }

                        &:hover {
                            color: var(--dark-blue);
                            background: var(--white);
                            transform: translateY(-10px);

                            > .reference {
                                color: var(--dark-blue-60);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .metaverse-block {
        .game-world-block {
            margin-bottom: 30px;
            background-position: top center;
            background-size: 768px 295px;
            min-height: auto;
            padding: 225px 15px 0 30px;

            > .container {
                > .title {
                    font-size: 24px;
                    line-height: 35px;
                    text-align: left;

                    &::after {
                        display: none;
                    }
                }

                > .sub-title {
                    font-size: 16px;
                    line-height: 24px;
                    text-align: left;
                    max-width: 215px;
                }

                > .description {
                    margin-top: 10px;
                    font-size: 12px;
                    line-height: 20px;
                    max-width: 100%;
                    text-align: left;
                }
            }
        }


        > .ecosystem-block {
            padding: 0 15px 35px;

            > .container {
                margin: 0 auto 0;

                > .title {
                    padding-left: 15px;
                    font-size: 24px;
                    line-height: 144%;
                }

                > .sub-title {
                    padding-left: 15px;
                    font-size: 14px;
                    line-height: 180%;
                }

                > .blocks {
                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;
                    row-gap: 10px;

                    > .item {
                        width: 100%;
                        padding: 30px 15px 20px;
                        align-items: center;

                        > svg {
                            margin-bottom: 10px;
                        }

                        > .title {
                            margin-bottom: 5px;
                            font-size: 16px;
                            line-height: 26px;
                        }

                        > .description {
                            margin-bottom: 20px;
                            font-size: 12px;
                            line-height: 20px;
                            min-height: auto;
                            text-align: center;
                        }

                        > .btn {
                            margin-bottom: 10px;
                            height: 40px;
                        }

                        &:hover {
                            color: var(--white);
                            background: linear-gradient(180deg, rgba(74, 98, 190, 0.3) 0%, rgba(114, 133, 202, 0.3) 100%);
                            transform: translateY(0px);

                            > .reference {
                                color: var(--white-60);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
